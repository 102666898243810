body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 0;
}

a{
  text-decoration: none;
}

a:visited, a:before, a:visited, a:link{
  color: whitesmoke;
}

.content{
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);
  background-color: black;
}